<template>
	<div id="index" ref="appRef">
		<div id="AMapContainer" style="width: 100%; height: 100%;">
			<div class="host-body1">
				<div class="d-flex jc-center header">
					<div class="d-flex logo">
						<img src="../assets/logo.png" />
					</div>
					<div class="rightFont">
						<div class="daydiv">
							<span>WED</span>
							<span>{{dateYear}}</span>
						</div>
						<div class="timediv">
							<span>{{dateDay}}</span>
						</div>
						<div class="weather">
							<span>{{tem}} ℃</span>
							<span>{{wea}}天</span>
						</div>
					</div>
				</div>
				<div class="titlebg">
					光大资源再生管理平台
				</div>

				<!-- 第二行 -->
				<div class="d-flex jc-between px-2" style="position: relative;">
					<div class="leftwhole">
						<div class="leftbox">
							<div class="list">
								<img src="../assets/c1.png" alt="" />
								<div class="rightfong">
									<span>当月运废车次</span>
									<span>{{dangyuecheci}}(车次)</span>
								</div>
							</div>
							<div class="list listnth">
								<img src="../assets/c2.png" alt="" />
								<div class="rightfong">
									<span>当月运废重量</span>
									<span>{{dangyuedunshu}}</span>
									<div class="msg">（吨）</div>
								</div>
							</div>
						</div>
						
						
						<div class="unitBox">
							<div class="number">单位总合计数：{{danweiTotal}} </div>
							<div>
								<div class="toplist">
									<div class="leftfont">
										<img src="../assets/wdicon.png" />
										<span>产废单位</span>
									</div>
								</div>
								<div class="tablist">
									<div class="list" @click="clickEnterprise(item)" v-for="(item,index) in danweiList"
										:key="index">
										<div>{{item.name}}</div>
										<span>累计运废:{{item.all_waste_weight}}</span>
									</div>
								</div>
							</div>
						</div>
						
						<div class="unitBox2">
							<div class="number">车辆：{{carTotal}} </div>
							<div>
								<div class="toplist">
									<div class="leftfont">
										<img src="../assets/car.png" />
										<span>运输车辆</span>
									</div>
								</div>
								<div class="tablist">
									<div class="list" @click="clickCar(item)" v-for="(item,index) in allCarList"
										:key="index">
										<!-- <div>{{item.company_name}}{{item.department}}</div> -->
										<div>车牌号:{{item.license_number}}</div>
										<!-- <span class="span-1">车牌号:{{item.license_number}}</span> -->
										<span class="span-2" v-if="item.transport_status  == 0" style="color:bisque;right: 20%">运输中</span>
										<span class="span-2" v-if="item.transport_status  == 1" style="color:aquamarine;right: 20%">空闲</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="righBox">
						<div class="boxlist">
							<div class="list">
								<img src="../assets/c1.png" alt="" />
								<div class="rightfong">
									<span>总计运废车次</span>
									<span>{{zongcheci}}(车次)</span>
								</div>
							</div>
							<div class="list listnth">
								<img src="../assets/c2.png" alt="" />
								<div class="rightfong">
									<span>总计运废重量</span>
									<span>{{zongdunshu}}</span>
									<div class="msg">（吨）</div>
								</div>
							</div>
						</div>

						<div class="unitBoxCenter">
							<div class="toplist">
								<div class="leftfont">
									<img src="../assets/lbicon.png" />
									<span>当前运单列表</span>
								</div>
								<div class="number">当前在运运单数量 : {{yundanTotal}}</div>
							</div>
							<div class="tablist">
								<div class="namelist">
									<span>车辆</span>
									<span>固废单位</span>
									<span>状态</span>
									<span>运废重量</span>
								</div>
								<div class="centerlist" @click="clickOrderInfo(item)" v-for="(item,index) in yundanList"
									:key="index">
									<span>{{item.car_license_number}}</span>
									<span>{{item.enterprise_name}}</span>
									<span>{{item.status_name}}</span>
									<span>{{item.weight}}</span>
								</div>
							</div>
						</div>
						<div class="kbbox" style="right: 30px;">
							<div class="toplist">
								<div class="leftfont">
									<img src="../assets/lzsh.png" />
									<span>资源再生</span>
								</div>
								<div class="number"></div>
							</div>
							<div class="echatbox">
								<div ref="mychart" style="width: 100%;height: 80%;margin-top: 25px;"></div>
							</div>

						</div>
					</div>
				</div>
			</div>

		</div>
		<transition name="fade" mode="out-in" v-if="showDialog">
			<!-- <div class="mask"> -->
			<div class="probg" v-if="openType == 'car'">
				<div class="close" @click="CloseDlog">
					<img src="../assets/close.png" />
				</div>
				<div class="titleFont">
					车牌号:{{carInfo.license_number}}
				</div>
				<div v-if="carInfo.order_no.length > 0">
					<div class="stateFont">
						司机:{{carInfo.driver_name}} 联系电话:{{carInfo.driver_mobile}}
					</div>
					<div class="stateFont">
						订单企业:{{carInfo.enterprise_name}}
					</div>
					<div class="stateFont">
						订单编号:{{carInfo.order_no}}
					</div>
				</div>
				<div v-if="carInfo.order_no.length == 0">
					<div class="stateFont">
						无订单信息
					</div>
				</div>
				<div class="stateImg">
<!-- 					<div>
						<span>正常</span>
						<img src="../assets/sateic.png" />
					</div> -->
					<!-- <div>
						<span>正常</span>
						<img src="../assets/sateic.png" />
					</div> -->
				</div>
			</div>
			<!-- </div> -->
			<div class="probg" v-if="openType == 'enterprise'">
				<div class="close" @click="CloseDlog">
					<img src="../assets/close.png" />
				</div>
				<div class="titleFont">
					企业名称:{{enterpriseInfo.name}}
				</div>
				<div class="stateFont">
					联系电话:{{enterpriseInfo.phone}}
				</div>
				<div class="stateFont">
					{{enterpriseInfo.address}}
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader';
	window._AMapSecurityConfig = {
		securityJsCode: '242aeba0fec00d99d80448daabfbab0d',
	}
	import axios from 'axios';
	import {
		formatTime
	} from '../utils/index.js'
	export default {
		data() {
			return {
				showDialog: false,
				openType: 'car',
				api_url: 'https://gdswr.cebenvironment.com.cn/',
				// api_url: 'https://gd.zhaomall.com.cn/',
				weatherData: {},
				books: [],
				responseData: null,
				timing: null,
				loading: true,
				dateDay: null,
				dateYear: null,
				dateWeek: null,
				weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
				decorationColor: ['#568aea', '#000000'],
				danweiList: [],
				yundanList: [],
				bingFenjian: [],
				zongcheci: 0,
				zongdunshu: 0,
				danweiTotal: 0,
				dangyuecheci: 0,
				dangyuedunshu: 0,
				yundanTotal: 0,
				map: null,
				tem: 0,
				wea: '',
				cityid: '330800000000',
				enterpriseInfo: {},
				carList: [],
				carInfo: {},
				markArr: [],
				allCarList:[],
				carTotal:0,
			}
		},
		created() {
			this.timeFn()
			this.cancelLoading();
			this.fetchData();
			this.getCarList();
			this.getWeather() // 获取天气
			let that = this;
			setInterval(function() {
				that.fetchData();
			}, 1000 * 120);
			
			setInterval(function() {
				that.getCarList();
			}, 1000 * 60);
		},
		beforeDestroy() {
			clearInterval(this.timing)
		},
		methods: {
			CloseDlog() {
				this.showDialog = false
			},
			cancelLoading() {
				let that = this;
				setTimeout(() => {
					that.loading = false
				}, 500)
			},
			getWeather() {
				// const apiKey = "70912b2d761738055bb6c4dce3b9cd17"; // 这里需要替换为自己的高德地图API密钥
				axios({
					method: 'get',
					url: 'https://v1.yiketianqi.com/free/day?appid=13331244&appsecret=O5DswogA&unescape=1'
				}).then((response) => {
					this.wea = response.data.wea; //天气
					this.tem = response.data.tem; //温度
					this.wea_img = response.data.wea_img; //图标名称
				}, function(response) {
					console.log("错误信息：" + response)
				});
			},
			fetchData() {
				axios.post(this.api_url + '/index/index/index') // 这里替换为真正的API地址
					.then(response => {
						this.clearMarker();
						// console.log(response.data); // 处理返回数据
						this.danweiList = response.data.danweiList;
						this.danweiTotal = response.data.danweiTotal;
						this.dangyuecheci = response.data.data.dangyuecheci;
						this.dangyuedunshu = response.data.data.dangyuedunshu;
						this.zongcheci = response.data.data.zongcheci;
						this.zongdunshu = response.data.data.zongdunshu;
						this.yundanList = response.data.data.yundanList;
						this.yundanTotal = response.data.data.yundanTotal;
						this.bingFenjian = response.data.data.bingFenjian;
						let that = this;
						setTimeout(function() {
							that.addEnterpriseMark(that.danweiList);
						}, 1000);
						// this.carList = response.data.data.car_list;
						// setTimeout(function() {
						// 	that.addCarMark(that.carList);
						// }, 1000);
						// console.log(this.carList);

					})
					.catch(error => {
						console.error(error); // 错误处理
					});
			},
			getCarList(){
				axios.post(this.api_url + '/index/index/carList') // 这里替换为真正的API地址
					.then(response => {
						this.clearMarker();
						this.carTotal = response.data.data.carTotal;
						this.allCarList = response.data.data.allCarList;
						let that = this;
						setTimeout(function() {
							that.addCarMark(that.allCarList);
						}, 1000);			
					})
					.catch(error => {
						console.error(error); // 错误处理
					});
			},
			timeFn() {
				this.timing = setInterval(() => {
					this.dateDay = formatTime(new Date(), 'HH: mm: ss')
					this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
					this.dateWeek = this.weekday[new Date().getDay()]
				}, 1000)
			},
			initAMap() {
				AMapLoader.reset();
				AMapLoader.load({
					key: "759d6df983643f41d972880b957736b2", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
						'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'
					], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					// 获取到作为地图容器的DOM元素，创建地图实例
					let centLon = 118.867099;
					let centLat = 28.884287;
					this.map = new AMap.Map("AMapContainer", { //设置地图容器id
						viewMode: "3D", //是否为3D地图模式
						zoom: 16, //初始化地图级别
						center: [centLon, centLat], //初始化地图中心点位置
					});

					// 设置地图主题样式为黑色
					this.map.setFitView();
					this.map.setMapStyle('amap://styles/dark');
					
					
					let marker = new window.AMap.Marker({
						position: [centLon, centLat],
						icon: new window.AMap.Icon({
							image: require("@/assets/guangda.png"),
							size: new window.AMap.Size(50,50),
							imageSize: new window.AMap.Size(50,50),
						}),
						offset: [-16, -32],
					});
					this.map.add(marker);
				}).catch(e => {
					console.log(e)
				})
			},
			initChart() {
				this.$nextTick(() => {
					const chartBox = this.$echarts.init(this.$refs.mychart);
					let option = {
						title: {
							text: '',
							subtext: '',
							left: 'center'
						},
						tooltip: {
							trigger: 'item',
							formatter: '{c}吨({d}%)',
						},
						legend: {
							show: true,
							orient: 'vertical',
							left: 'left',
							top:'5%',
							textStyle: {
								color: '#fff' // 这里设置为红色
							},
						},
						series: [{
							name: '分拣仓库',
							type: 'pie',
							radius: '50%',
							center: ['60%', '50%'],
							data: [

							],
							label: {
								textStyle: {
									color: '#fff' // 这里设置为红色
								},
								color: '#fff',
								position: 'outer',
								alignTo: 'labelLine',
								bleedMargin: 5 //文字的出血线大小，超过出血线的文字将被裁剪为 '...'。
								// 仅当 label.position 为 'outer' 并且 label.alignTo 为 'none' 或 'labelLine' 的情况有效
							},
							emphasis: {

							}
						}]
					};
					if (this.bingFenjian.x.length > 0) {
						let a = ['#30FDFF', '#fff', '#fff'];
						for (let i in this.bingFenjian.x) {
							option.series[0].data.push({
								value: this.bingFenjian.y[i].value,
								name: this.bingFenjian.x[i],
								itemStyle: {
									color: a[i],
									shadowBlur: 40, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
									shadowOffsetX: 10, // 阴影水平方向上的偏移距离
									shadowOffsetY: 10,
									shadowColor: 'rgba(0,0,0,0.5)', // 阴影颜色
									opacity: 0.5,
								},

							});
						}
					}
					chartBox.setOption(option);
					// 根据页面大小自动响应图表大小
					window.addEventListener("resize", function() {
						chartBox.resize();
					});
				});
			},
			addEnterpriseMark(enterpriseList) {
				if (enterpriseList.length > 0) {
					let info;
					for (let i in enterpriseList) {
						info = enterpriseList[i];
						let lat = info.latitude;
						let long = info.longitude;

						let marker = new window.AMap.Marker({
							position: [long, lat],
							icon: new window.AMap.Icon({
								image: require("@/assets/wdicon.png"),
								size: new window.AMap.Size(40, 40),
								imageSize: new window.AMap.Size(40, 40),
							}),
							offset: [-16, -32],
						});
						marker.on('click', () => {
							this.map.setCenter([long, lat]);
							this.openType = 'enterprise';
							this.showDialog = true;
							this.enterpriseInfo = info;
						});
						this.map.add(marker);
						this.markArr.push(marker);
					}
				}
			},
			clickEnterprise(info) {
				this.enterpriseInfo = info;
				this.openType = 'enterprise';
				this.showDialog = true;
				let lat = info.latitude;
				let long = info.longitude;
				this.map.setCenter([long, lat]);
			},
			clickCar(info) {
				// this.enterpriseInfo = info;
				// this.openType = 'enterprise';
				// this.showDialog = true;
				this.carInfo = info;
				console.log(info);
				let lat = info.latitude;
				let long = info.longitude;
				this.map.setCenter([long, lat]);
			},
			addCarMark(carList) {
				// console.log(carList);
				if (carList.length > 0) {
					// let info;
					// 
					for (let i in carList) {
						let info = carList[i];
						// console.log(info);
						let lat = info.latitude;
						let long = info.longitude;

						let marker = new window.AMap.Marker({
							position: [long, lat],
							icon: new window.AMap.Icon({
								image: require("@/assets/car1.png"),
								size: new window.AMap.Size(25, 18),
								imageSize: new window.AMap.Size(25, 18),
							}),
							offset: [-16, -32],
						});
						marker.on('click', () => {
							this.map.setCenter([long, lat]);
							this.openType = 'car';
							this.showDialog = true;
							console.log(info);
							this.carInfo = info;
							
						});
						this.map.add(marker);
						this.markArr.push(marker);
					}
				}
			},
			clickOrderInfo(info) {
				let lat = info.latitude;
				let long = info.longitude;
				if (lat && long) {
					this.carInfo = info;
					this.openType = 'car';
					this.showDialog = true;
					this.map.setCenter([long, lat]);
				}
			},
			clearMarker() {
				if (this.markArr && this.markArr.length > 0) {
					this.markArr.map((ele) => {
						ele.setMap(null);
					});
				}
			},
		},
		mounted() {
			//DOM初始化完成进行地图初始化
			this.initAMap();
			let that = this;
			setTimeout(function() {
				that.initChart(); // 页面加载完成后调用初始化函数
				// that.enterFullScreen();
			}, 1000);
		}
	}
</script>
<style>
	/* 整个滚动条 */
	::-webkit-scrollbar {
		width: 10px;
		/* 滚动条宽度 */
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		/* 轨道颜色 */
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		background: #888;
		/* 滑块颜色 */
	}

	/* 滑块hover效果 */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
		/* 滑块hover颜色 */
	}

	::-webkit-scrollbar {
		display: none;
		/* 对于Chrome, Safari和Opera等基于Webkit的浏览器 */
	}
</style>
<style lang="scss" scoped>
	@import '../assets/scss/index.scss';
</style>